import PropTypes from 'prop-types'
import { createContext, useEffect, useState } from 'react'

const propTypes = {
  children: PropTypes.element
}

const SharedDataContext = createContext()

const SharedDataProvider = ({ children }) => {
  const [error, setError] = useState()
  const [banner, setBanner] = useState()
  const [user, setUser] = useState()
  const [tournament, setTournament] = useState()
  const [features, setFeatures] = useState()

  const resetData = () => {
    setError()
    setUser()
    setTournament()
    setFeatures()
  }

  const storeTournamentValue = (t) => {
    setTournament(t)
    if (!t) {
      window.localStorage.removeItem('tournament')
    } else {
      window.localStorage.setItem('tournament', JSON.stringify(t))
    }
  }

  useEffect(() => {
    try {
      const t = JSON.parse(window.localStorage.getItem('tournament'))
      // TODO: remove in future release
      if (typeof t.stringers[0] === 'string') {
        throw new Error()
      }
      setTournament(t)
    } catch (e) {
      console.log('Tournament needs to be selected again')
      window.localStorage.removeItem('tournament')
    }
  }, [])

  const value = {
    error,
    setError,
    banner,
    setBanner,
    user,
    setUser,
    tournament,
    setTournament: storeTournamentValue,
    features,
    setFeatures,
    resetData
  }

  return (
    <SharedDataContext.Provider value={value}>
      { children }
    </SharedDataContext.Provider>
  )
}

SharedDataProvider.propTypes = propTypes

export {
  SharedDataContext,
  SharedDataProvider
}
