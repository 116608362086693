import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import RacquetTag from './labels/RaquetTag'
import ThroatSticker from './labels/ThroatSticker'
import useDataService from '../../hooks/useDataService'
import { usePDF } from '@react-pdf/renderer'
import Icon from '../../icons/Icon'
import { useContext, useEffect, useState } from 'react'
import { OrderContext } from '../../contexts/OrderContext'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../routes'

const PrintLabelsButton = () => {
  const { order } = useContext(OrderContext)
  const { apiGetTournament, apiCreatePrintJob } = useDataService()
  const [racquetTagFile, updateRacquetTagFile] = usePDF({ document: RacquetTag({ order }) })
  const [throatStickerFile] = usePDF({ document: ThroatSticker({ order }) })

  const [loading, setLoading] = useState(true)
  const [success, setSuccess] = useState(false)
  const [tournament, setTournament] = useState(false)

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        resolve(reader.result)
      }
      reader.onerror = (error) => {
        reject(error)
      }
    })
  }
  const printLabels = async (rtBlob, tsBlob) => {
    setLoading(true)
    const promises = [blobToBase64(rtBlob), blobToBase64(tsBlob)]
    const [rt, ts] = await Promise.all(promises)
    await apiCreatePrintJob(order._id, {
      racquetTags: rt.split(',')[1],
      throatStickers: ts.split(',')[1]
    })
    setLoading(false)
    setSuccess(true)
  }

  useEffect(() => {
    apiGetTournament(order.tournament._id).then(t => {
      setTournament(t)
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    let delay
    if (success) {
      delay = setTimeout(() => setSuccess(false), 4000)
    }

    return () => clearTimeout(delay)
  }, [success])

  useEffect(() => {
    updateRacquetTagFile(RacquetTag({ order }))
  }, [order])

  if (loading) {
    return (
      <Button
        type='button'
        variant='info'
        className='text-body'
        disabled
      >
        <Icon icon='loader' size={16} />
        <span>Loading...</span>
      </Button>
    )
  } else if (!tournament.printerSettings) {
    return (
      <OverlayTrigger
        trigger={['click']}
        placement='bottom-end'
        defaultShow={true}
        overlay={<Tooltip>Go to <Link to={ROUTES.PrinterSettings}>Printers</Link> and select printers for this tournament.</Tooltip>}
      >
        <span className="d-inline-block">
          <Button
            type='button'
            variant='info'
            disabled
            style={{ pointerEvents: 'none' }}
          >Connect printer</Button>
        </span>
      </OverlayTrigger>
    )
  } else if (success) {
    return (
      <Button
        type='button'
        variant='info'
        className='text-success'
      >
        <Icon icon='success' size={16} />
        <span>Printed!</span>
      </Button>
    )
  }

  return (
    <Button
      type='button'
      variant='info'
      disabled={!(racquetTagFile.blob && throatStickerFile.blob) || racquetTagFile.loading || throatStickerFile.loading}
      onClick={() => printLabels(racquetTagFile.blob, throatStickerFile.blob)}
    >
      <Icon icon='printer' size={16} />
      <span>Print labels</span>
    </Button>
  )
}

export default PrintLabelsButton
