import { Col, Row } from 'react-bootstrap'

const Footer = () => {
  return (
    <Row id='footer' className='pt-5 pb-3'>
      <Col xs={12} md={6} className='text-center mt-5'>
        <img src='/images/logo-inverted.svg' style={{ maxWidth: '130px' }}/>
      </Col>
      <Col xs={12} md={6} className='text-center mt-5'>
        <p className='text-white'>&copy; 2024 Stringr LLC</p>
      </Col>
      <Col md={6} className='mt-2 text-center text-small text-white-50'>
        <p>v{process.env.REACT_APP_VERSION}</p>
      </Col>
    </Row>
  )
}

export default Footer
