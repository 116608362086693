import './App.scss'
import { Col, Container, Row } from 'react-bootstrap'
import { Navigate, Route, Routes } from 'react-router-dom'
import PrivateRoute from './components/generic/PrivateRoute'
import { ROUTES } from './routes'
import Dashboard from './pages/Dashboard'
import TokenRefresh from './pages/TokenRefresh'
import StaffLogIn from './pages/StaffLogin'
import PlaceOrder from './pages/PlaceOrder'
import Order from './pages/Order'
import SignUp from './pages/SignUp'
import Home from './pages/Home'
import EmailActionHanlder from './pages/EmailActionHandler'
import Navigation from './components/Navigation'
import Style from './pages/Style'
import Menu from './components/Menu'
import { AlertHeading, CustomAlert } from './components/generic/CustomAlert'
import Icon from './icons/Icon'
import { useContext, useEffect } from 'react'
import { SharedDataContext } from './contexts/SharedDataContext'
import ForgotPassword from './pages/ForgotPassword'
import { Settings } from 'luxon'
import useDataService from './hooks/useDataService'
import useAuth from './hooks/useAuth'
import Footer from './components/Footer'
import PrinterSettings from './pages/PrinterSettings'
import Banner from './components/common/Banner'
import Report from './pages/Report'
import Reports from './pages/Reports'
import Unauthorized from './pages/Unauthorized'
import { ROLE } from './helpers'
import EditOrder from './pages/EditOrder'
import AboutUs from './pages/AboutUs'
import Paywall from './components/common/Paywall'
import Events from './pages/Events'
import CreateEvent from './pages/CreateEvent'
import Event from './pages/Event'
import Invoices from './pages/Invoices'
import Customers from './pages/Customers'
import MyProfile from './pages/MyProfile'
import ChangePassword from './pages/ChangePassword'
import CompleteProfile from './pages/CompleteProfile'

function App () {
  const { error, setUser, tournament, features, setFeatures, resetData } = useContext(SharedDataContext)
  Settings.defaultZone = 'Australia/Sydney'
  Settings.defaultLocale = 'en-AU'

  const { currentUser, refreshToken } = useAuth()
  const { apiGetMyProfile, apiGetFeatures } = useDataService()
  const { user } = useContext(SharedDataContext)

  useEffect(() => {
    if (currentUser) {
      if (!currentUser.role && user) {
        refreshToken().then(() => apiGetMyProfile()).then(setUser)
      } else if (!user) {
        apiGetMyProfile().then(setUser)
      }

      apiGetFeatures().then(setFeatures)
    } else {
      resetData()
    }
  }, [currentUser, user, tournament])

  if (currentUser && !features) {
    return null
  }

  return (
    <Container fluid>
      <Banner/>
      <Navigation />
      {currentUser && features && <Paywall init={features.createOrder === false}/> }
      <Row id='layout'>
        <Col md={2} id='sidebar-wrapper' className='d-none d-md-block px-0'>
          <Menu />
        </Col>
        <Col md={10} xs={12} id='page-content-wrapper' className='px-md-0 pt-5 pt-md-4'>
          {error && <CustomAlert dismissible={true} variant='danger' type='error'>
            <AlertHeading>
              <Icon icon='error' />
              <span>{error.summary}</span>
            </AlertHeading>
            <p className='text-muted'>{error.detail}</p>
          </CustomAlert>
          }
          <Routes>
            <Route path={ROUTES.Home} element={<Home />} />
            <Route path='/style' element={<Style />} />
            <Route path={ROUTES.StaffLogIn} element={<StaffLogIn />} />
            <Route path={ROUTES.ForgotPassword} element={<ForgotPassword />} />
            <Route path={ROUTES.SignUp} element={<SignUp />} />
            <Route path={ROUTES.Auth} element={<EmailActionHanlder />} />
            <Route path={ROUTES.Unauthorized} element={<Unauthorized/>} />
            <Route path={ROUTES.AboutUs} element={<AboutUs/>} />
            <Route path={ROUTES.Dashboard} element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            } />
            <Route path={ROUTES.TokenRefresh} element={
              <PrivateRoute>
                <TokenRefresh />
              </PrivateRoute>
            } />
            <Route path={ROUTES.CompleteProfile} element={
              <PrivateRoute>
                <CompleteProfile />
              </PrivateRoute>
            } />
            <Route path={`${ROUTES.Orders}/:id/edit`} element={
              <PrivateRoute roles={[ROLE.Admin, ROLE.SuperUser, ROLE.SiteOwner]}>
                <EditOrder />
              </PrivateRoute>
            } />
            <Route path={`${ROUTES.Orders}/:id`} element={
              <PrivateRoute>
                <Order />
              </PrivateRoute>
            } />
            { features?.createOrder && <Route path={ROUTES.Order} element={
              <PrivateRoute roles={[ROLE.Player, ROLE.Admin, ROLE.SuperUser, ROLE.SiteOwner]}>
                <PlaceOrder />
              </PrivateRoute>
            } /> }
            <Route path={ROUTES.SelectTournament} element={
              <PrivateRoute>
                <Events />
              </PrivateRoute>
            } />
            <Route path={ROUTES.CreateTournament} element={
              <PrivateRoute roles={[ROLE.SuperUser, ROLE.SiteOwner]}>
                <CreateEvent />
              </PrivateRoute>
            } />
            <Route path={`${ROUTES.Tournaments}/:id`} element={
              <PrivateRoute roles={[ROLE.SuperUser, ROLE.SiteOwner, ROLE.Admin]}>
                <Event />
              </PrivateRoute>
            } />
            <Route path={ROUTES.PrinterSettings} element={
              <PrivateRoute roles={[ROLE.Admin, ROLE.SuperUser, ROLE.SiteOwner]}>
                <PrinterSettings/>
              </PrivateRoute>
            } />
            <Route path={ROUTES.Reports} element={
              <PrivateRoute>
                <Reports />
              </PrivateRoute>
            }/>
            <Route path={`${ROUTES.Reports}/:id`} element={
              <PrivateRoute>
                <Report />
              </PrivateRoute>
            }/>
            <Route path={ROUTES.Invoices} element={
              <PrivateRoute>
                <Invoices />
              </PrivateRoute>
            }/>
            <Route path={ROUTES.MyProfile} element={
              <PrivateRoute>
                <MyProfile />
              </PrivateRoute>
            } />
            <Route path={ROUTES.ChangePassword} element={
              <PrivateRoute>
                <ChangePassword />
              </PrivateRoute>
            } />
            <Route path={`${ROUTES.Customers}/:id?`} element={
              <PrivateRoute roles={[ROLE.Admin, ROLE.SuperUser, ROLE.SiteOwner]}>
                <Customers />
              </PrivateRoute>
            } />

            {/* <Route path={ROUTES.Error} element={<AppError />}/> */}
            <Route path="*" element={<Navigate to={ROUTES.Dashboard} />} />
          </Routes>
        </Col>
      </Row>
      <Footer/>
    </Container>
  )
}

export default App
