import { omit } from 'lodash'
import { useEffect, useState } from 'react'
import { Button, Col, Dropdown, Form, Row, Table } from 'react-bootstrap'
import useDataService from '../../hooks/useDataService'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../routes'
import Icon from '../../icons/Icon'
import { useFormikContext } from 'formik'
import Filters from './Filters'
import PropTypes from 'prop-types'
import StatusControl from '../order-view/StatusBadge'
import useAuth from '../../hooks/useAuth'
import ListPagination from '../common/ListPagination'
import UserIcon from '../common/UserIcon'
import { DateTime } from 'luxon'
import { ROLE } from '../../helpers'
import StringerSelection from '../order-view/StringerSelection'
import PaymentControlCell from './PaymentControlCell'

const COLUMNS = {
  OrderId: 'Order #',
  Customer: 'Customer',
  Stringer: 'Stringer',
  DueDate: 'Due date',
  Racquets: 'Racquets',
  Status: 'Status',
  Payment: 'Payment'
}

const sortOptions = {
  OrderIdAsc: {
    title: 'Order ID (asc)',
    sortField: 'id',
    sortDirection: 'asc'
  },
  OrderIdDesc: {
    title: 'Order ID (desc)',
    sortField: 'id',
    sortDirection: 'desc'
  },
  DueDateAsc: {
    title: 'Due date (asc)',
    sortField: 'dueDate',
    sortDirection: 'asc'
  },
  DueDateDesc: {
    title: 'Due date (desc)',
    sortField: 'dueDate',
    sortDirection: 'desc'
  },
  StatusAsc: {
    title: 'Status (new first)',
    sortField: 'status',
    sortDirection: 'asc'
  },
  StatusDesc: {
    title: 'Status (completed first)',
    sortField: 'status',
    sortDirection: 'desc'
  },
  PaymentAsc: {
    title: 'Payment (asc)',
    sortField: 'paid',
    sortDirection: 'asc'
  },
  PaymentDesc: {
    title: 'Payment (desc)',
    sortField: 'paid',
    sortDirection: 'desc'
  }
}

const propTypes = {
  filter: PropTypes.bool,
  search: PropTypes.bool
}

const OrderList = ({ filter, search }) => {
  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const navigate = useNavigate()
  const { currentUser } = useAuth()
  const { apiGetOrders } = useDataService()
  const [orders, setOrders] = useState([])
  const [isMobile, setIsMobile] = useState(true)
  const [columns, setColumns] = useState([])
  const [stringerOffcanvas, setStringerOffcanvas] = useState({ show: false })
  const formik = useFormikContext()

  const [showFilters, setShowFilters] = useState(false)

  const updatePredicate = () => {
    setIsMobile(window.innerWidth < 768)
  }

  useEffect(() => {
    updatePredicate()
    window.addEventListener('resize', updatePredicate)
    return () => {
      window.removeEventListener('resize', updatePredicate)
    }
  }, [])

  useEffect(() => {
    const delay = setTimeout(async () => {
      const res = await apiGetOrders({ ...formik.values.filters }, formik.values.sort, formik.values.search, page, pageSize)
      setOrders(res.orders)
      setTotalPages(res.totalPages)
    }, 500)

    return () => clearTimeout(delay)
  }, [formik.values.search])

  useEffect(() => {
    apiGetOrders({ ...formik.values.filters }, formik.values.sort, formik.values.search, page, pageSize).then(res => {
      setOrders(res.orders)
      setTotalPages(res.totalPages)
    })
  }, [formik.values.sort, formik.submitCount, page, pageSize])

  useEffect(() => {
    if (isMobile) {
      if (currentUser.role === ROLE.Player) {
        setColumns([
          COLUMNS.OrderId,
          COLUMNS.Tournament,
          COLUMNS.Status
        ])
      } else {
        setColumns([
          COLUMNS.OrderId,
          COLUMNS.Status,
          COLUMNS.Stringer
        ])
      }
    } else {
      if (currentUser.role === ROLE.Player) {
        setColumns(Object.values(omit(COLUMNS, 'Stringer')))
      } else {
        setColumns(Object.values(COLUMNS, 'Tournament'))
      }
    }
  }, [isMobile])

  const getFilterCount = () => {
    return Object.keys(formik.values.filters).reduce((count, f) => {
      if (f === 'tournament') {
        return count
      }
      if (Array.isArray(formik.values.filters[f]) && formik.values.filters[f].length > 0) {
        return count + 1
      } else if (!Array.isArray(formik.values.filters[f]) && formik.values.filters[f] !== '' && formik.values.filters[f] !== true) {
        return count + 1
      }
      return count
    }, 0)
  }

  return (
    <>
      <Filters show={showFilters} close={() => setShowFilters(false)} />
      {[ROLE.Admin, ROLE.SuperUser, ROLE.SiteOwner].includes(currentUser.role) && (
        <StringerSelection {...stringerOffcanvas} update={formik.submitForm} close={() => setStringerOffcanvas({ show: false })} />
      )}
      <Row className='my-2 mx-1'>
        <Col xs={8} lg={3} className='d-flex align-items-center'>
          <Dropdown>
            <Dropdown.Toggle variant="ghost">
              <span className='text-muted'><Icon icon='sort' size={18} /></span>
              <span>{formik.values.sort.title}</span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {Object.keys(sortOptions).map(c => (
                <Dropdown.Item key={c} onClick={() => formik.setFieldValue('sort', sortOptions[c])}>{sortOptions[c].title}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col xs={2} lg={3} className='d-flex'>
          {!isMobile && <span className='text-small me-1'>orders<br/>per page:</span>}
          <Dropdown>
              <Dropdown.Toggle variant="input">
                <span>{pageSize}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {[10, 20, 50, 100].map(c => (
                  <Dropdown.Item key={c} onClick={() => setPageSize(c)} active={pageSize === c}>{c}</Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
        </Col>
        {filter && <Col xs={2} lg={1} className='d-flex align-items-center justify-content-end'>
          {getFilterCount() > 0 && <span className='filter-counter'>{getFilterCount()}</span>}
          <Button
            type='button'
            variant='icon-wrapper'
            onClick={() => setShowFilters(true)}
          >
            <Icon icon='filter' size={16} />
          </Button>
        </Col>
        }
        {search && <Col xs={12} lg={5}>
          <Form noValidate>
            <Form.Group controlId='search'>
              <Form.Control
                type='text'
                value={formik.values.search}
                onChange={formik.handleChange}
                placeholder="Search..."
              />
              <span><Icon icon='search' /></span>
            </Form.Group>
          </Form>
        </Col>
        }
      </Row>
      <Table hover className='order-list'>
        <thead>
          <tr className='p-5'>
            {columns.map(c => (
              <th key={c}>{c}</th>
            ))}
          </tr>
        </thead>
        {orders.length > 0 && (
          <tbody>
            {orders.map(o => (
              <tr key={o._id} onClick={() => navigate(`${ROUTES.Orders}/${o._id}`)}>
                {columns.map(c => {
                  switch (c) {
                    case COLUMNS.OrderId:
                      return <td key={`${o._id}-${c}`}>#{o.id}</td>
                    case COLUMNS.Customer:
                      return <td key={`${o._id}-${c}`}>
                        <Button
                          variant='ghost'
                          className='w-100'
                          type='button'
                          onClick={e => {
                            e.stopPropagation()
                            navigate(`${ROUTES.Customers}/${o.customer._id}`)
                          }}
                        >
                          <UserIcon user={o.customer} size='sm' />
                          <span className='ms-1 flex-grow-1'>{o.customer?.firstName} {o.customer?.lastName}</span>
                        </Button>
                      </td>
                    case COLUMNS.Tournament:
                      return <td key={`${o._id}-${c}`}>{o.tournament?.name}</td>
                    case COLUMNS.Stringer:
                      if ([ROLE.Admin, ROLE.SuperUser, ROLE.SiteOwner].includes(currentUser.role)) {
                        return <td key={`${o._id}-${c}`}>
                          <Button
                            variant='ghost'
                            className='w-100'
                            type='button'
                            onClick={(e) => {
                              e.stopPropagation()
                              setStringerOffcanvas({
                                show: true,
                                orderId: o._id,
                                friendlyOrderId: o.id,
                                initialSelected: o.stringer?._id
                              })
                            }}
                          >
                            {o.stringer
                              ? (
                                <>
                                  <UserIcon user={o.stringer} size='sm' />
                                  <span className='ms-1 flex-grow-1 text-start'>{o.stringer?.firstName} {o.stringer?.lastName}</span>
                                  <Icon icon='drop-arrow' size={16} />
                                </>
                                )
                              : <>
                                  <span className='flex-grow-1'>Assign</span>
                                  <Icon icon='drop-arrow' size={16} />
                                </>
                              }
                          </Button>
                        </td>
                      } else if (o.stringer) {
                        return <td key={`${o._id}-${c}`}>
                          <UserIcon user={o.stringer} size='sm' />
                          <span className='ms-1'>{o.stringer?.firstName} {o.stringer?.lastName}</span>
                        </td>
                      } else {
                        return <td key={`${o._id}-${c}`}>Not Assigned</td>
                      }
                    case COLUMNS.DueDate:
                      return <td key={`${o._id}-${c}`}>{DateTime.fromISO(o.dueDate).toLocaleString(DateTime.DATETIME_SHORT)}</td>
                    case COLUMNS.Racquets:
                      return <td key={`${o._id}-${c}`}>{o.racquets.length ?? o.racquets}</td>
                    case COLUMNS.Status:
                      return <td key={`${o._id}-${c}`}><StatusControl orderId={o._id} friendlyOrderId={o.id} update={formik.submitForm} status={o.status} cancelled={o.cancelled} /></td>
                    case COLUMNS.Payment:
                      return <td key={`${o._id}-${c}`}><PaymentControlCell order={o} update={formik.submitForm} /></td>
                    default:
                      return null
                  }
                })}
              </tr>
            ))}
          </tbody>
        )}
      </Table>
      {orders.length > 0 && <ListPagination page={page} setPage={setPage} totalPages={totalPages} /> }
      {orders.length === 0 && <div className='text-center'>No orders found</div>}
    </>
  )
}

OrderList.propTypes = propTypes

export default OrderList
