import { Col, Container, Nav, Row, Tab } from 'react-bootstrap'
import EventInfo from '../components/events/EventInfo'
import StaffManagement from '../components/events/StaffManagement'
import { EventContext } from '../contexts/EventContext'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import useDataService from '../hooks/useDataService'
import { DateTime } from 'luxon'
import PrinterSettingsForm from '../components/events/PrinterSettingsForm'
import useAuth from '../hooks/useAuth'
import { ROLE } from '../helpers'

const Event = () => {
  const { id } = useParams()
  const { currentUser } = useAuth()
  const [event, setEvent] = useState()
  const { apiGetTournament } = useDataService()

  useEffect(() => {
    refresh()
  }, [])

  const refresh = async () => {
    await apiGetTournament(id).then(setEvent)
  }

  const TABS = {
    General: {
      key: 'info',
      title: 'General Info'
    },
    Staff: {
      key: 'staff',
      title: 'Staff'
    },
    Printers: {
      key: 'printers',
      title: 'Printers'
    }
  }

  return (
    <EventContext.Provider value={{ event, setEvent, refresh }}>
      {event && <Container fluid className='mx-md-0 px-md-0 mw-750'>
        <Row className='mx-0 px-1 mb-3 justify-content-between align-items-end'>
          <Col xs='auto'>
            <h3>{event.name}</h3>
            <p className='text-body'>{DateTime.fromISO(event.startDate).toLocaleString(DateTime.DATE_MED)} - {DateTime.fromISO(event.endDate).toLocaleString(DateTime.DATE_MED)}</p>
          </Col>
        </Row>
        <Tab.Container defaultActiveKey={TABS.General.key}>
          <Nav variant='tabs' className='ps-2'>
            <Nav.Item>
              <Nav.Link eventKey={TABS.General.key}>{TABS.General.title}</Nav.Link>
            </Nav.Item>
            {[ROLE.SuperUser, ROLE.SiteOwner].includes(currentUser.role) && (
              <Nav.Item>
                <Nav.Link eventKey={TABS.Staff.key}>{TABS.Staff.title}</Nav.Link>
              </Nav.Item>
            )}
            <Nav.Item>
              <Nav.Link eventKey={TABS.Printers.key}>{TABS.Printers.title}</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey={TABS.General.key}><EventInfo /></Tab.Pane>
            <Tab.Pane eventKey={TABS.Staff.key}><StaffManagement /></Tab.Pane>
            <Tab.Pane eventKey={TABS.Printers.key}><PrinterSettingsForm /></Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Container>
      }
    </EventContext.Provider>
  )
}

export default Event
