import { Col, Container, Row } from 'react-bootstrap'
import useDataService from '../hooks/useDataService'
import { useContext, useRef, useState } from 'react'
import { Formik } from 'formik'
import { date, object, string } from 'yup'
import { DateTime } from 'luxon'
import useAuth from '../hooks/useAuth'
import { ROLE } from '../helpers'
import PlayerInvoiceForm from '../components/invoices/PlayerInvoiceForm'
import AdminInvoiceForm from '../components/invoices/AdminInvoiceForm'
import { SharedDataContext } from '../contexts/SharedDataContext'
import withCompleteProfileRequired from '../components/generic/withCompleteProfileRequired'

const Invoices = () => {
  const { currentUser } = useAuth()
  const { setBanner } = useContext(SharedDataContext)
  const { apiGetInvoiceData, apiEmailInvoice } = useDataService()
  const csvLinkRef = useRef(null)
  const [error, setError] = useState()

  const playerInvoiceSchema = object({
    startDate: date().required('Enter the start date'),
    endDate: date().required('Enter the end date').when('startDate', (startDate, yup) => {
      return startDate && yup.min(startDate)
    })
  })

  const adminInvoiceSchema = object({
    customer: object({
      _id: string().required(),
      invoiceRecipient: string().email().required()
    })
  }).concat(playerInvoiceSchema)

  const downloadCSV = async (values, bag) => {
    setError()
    bag.setSubmitting(true)
    const res = await apiGetInvoiceData(values.startDate, values.endDate)
    if (res.ok) {
      const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${res.data}`)
      csvLinkRef.current.download = `stringr_invoice_${values.startDate}_${values.endDate}`
      csvLinkRef.current.href = encodedUri
      csvLinkRef.current.click()
    } else {
      setError('There are no orders in the selected range')
    }
    bag.setSubmitting(false)
  }

  const sendInvoice = async (values, bag) => {
    setError()
    bag.setSubmitting(true)
    const res = await apiEmailInvoice(values)
    if (res.ok) {
      setBanner('Email sent!')
      bag.resetForm()
    } else {
      setError('There are no orders in the selected range')
    }
    bag.setSubmitting(false)
  }

  return (
    <Container fluid className='mx-md-0 px-md-0 mw-750'>
      <a href="/" ref={csvLinkRef} className="d-none">Invoice</a>
      <Row className='mx-0 px-1 pb-3 mb-3 border-bottom justify-content-between align-items-end'>
        <Col xs='auto'>
          <h3>Generate an invoice</h3>
          <p>Select a date range and {currentUser.role === ROLE.Player ? 'donwload' : 'email'} a CSV file with a list of relevant orders.</p>
        </Col>
      </Row>
      <Formik
        onSubmit={currentUser.role === ROLE.Player ? downloadCSV : sendInvoice}
        initialValues={{
          startDate: DateTime.now().set({ month: 1, day: 1 }).toISODate(),
          endDate: DateTime.now().toISODate(),
          customer: {
            _id: '',
            invoiceRecipient: ''
          }
        }}
        validationSchema={currentUser.role === ROLE.Player ? playerInvoiceSchema : adminInvoiceSchema}
      >
        { currentUser.role === ROLE.Player
          ? <PlayerInvoiceForm/>
          : <AdminInvoiceForm />
        }
      </Formik>
      {error &&
        <Row>
          <Col>
            <p className='text-danger text-center'>{error}</p>
          </Col>
        </Row>
      }
    </Container>
  )
}

export default withCompleteProfileRequired(Invoices)
