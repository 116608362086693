import { Accordion, AccordionButton, AccordionContext, Card, Col } from 'react-bootstrap'
import { pull } from 'lodash'
import PropTypes from 'prop-types'
import RacquetCardBody from '../common/RacquetCardBody'
import { useContext, useState } from 'react'

const propTypes = {
  racquets: PropTypes.array.isRequired
}

const RacquetAccordion = ({ racquets }) => {
  const [activeKey, setActiveKey] = useState([])

  const handleSelect = eventKey => {
    if (activeKey.includes(eventKey)) {
      setActiveKey([...pull(activeKey, eventKey)])
    } else {
      setActiveKey([...activeKey, eventKey])
    }
  }

  return (
    <Accordion flush alwaysOpen activeKey={activeKey} onSelect={handleSelect}>
      {racquets.map((r) => (
        <RacquetCard r={r} key={r._id} eventKey={r._id} />
      ))}
    </Accordion>
  )
}

RacquetAccordion.propTypes = propTypes

export default RacquetAccordion

const racquetCardPropTypes = {
  r: PropTypes.object.isRequired,
  eventKey: PropTypes.string.isRequired
}

const RacquetCard = ({ r, eventKey }) => {
  const { onSelect } = useContext(AccordionContext)

  return (
    <Card bg='white' className='racquet-card border-0 px-1 pb-2 mb-2'>
      <Card.Header className='bg-white border-0'>
          <Col>
            <AccordionButton
              className='bg-white fs-5 fw-medium ps-0'
              onClick={(e) => onSelect(eventKey, e)}
            >{r.name}</AccordionButton>
          </Col>
      </Card.Header>
      <Accordion.Collapse eventKey={r._id}>
      <RacquetCardBody racquet={r} showPrices />
      </Accordion.Collapse>
      <Card.Footer className='bg-white border-0 d-flex justify-content-between align-items-end'>
        <Col xs='auto' className='py-1 px-3 border rounded'>
          <span className='text-muted'>QTY:</span> {r.quantity}
        </Col>
        <Col xs='auto'>
          <h5>${r.subtotal.toFixed(2)}</h5>
        </Col>
      </Card.Footer>
    </Card>
  )
}

RacquetCard.propTypes = racquetCardPropTypes
