import { Container, Row, Table } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../routes'
import Icon from '../icons/Icon'

const Reports = () => {
  const navigate = useNavigate()
  const reports = [
    {
      name: 'Unpaid orders report',
      desc: 'List of all unpaid orders within a tournament',
      path: '/unpaid-by-tournament'
    },
    {
      name: 'Restrung racquets report',
      desc: 'List of restrings completed by each stringer within a tournament',
      path: '/restrings-by-tournament'
    }
  ]
  return (
    <Container fluid className='mx-md-0 px-md-0'>
      <Row className='mx-1 mb-2'>
        <h2>Reports</h2>
      </Row>
      <Table bordered striped hover>
        <thead>
          <tr>
            <th className='border-left-0 text-muted'>Name</th>
            <th className='border-right-0 text-muted'>Description</th>
          </tr>
        </thead>
        <tbody>
          {reports.map(r => (
            <tr key={r.path} onClick={() => navigate(`${ROUTES.Reports}${r.path}`)}>
              <td className='border-left-0'>{r.name}</td>
              <td className='border-right-0 text-muted'>
                <div className='d-flex justify-content-between'>
                  <span>{r.desc}</span>
                  <Icon icon='forward' size={24}/>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  )
}

export default Reports
