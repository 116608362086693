import PropTypes from 'prop-types'
import { ReactComponent as Close } from './close.svg'
import { ReactComponent as Pencil } from './pencil.svg'
import { ReactComponent as Arrow } from './arrow.svg'
import { ReactComponent as Loader } from './loader.svg'
import { ReactComponent as Mail } from './mail.svg'
import { ReactComponent as Reload } from './reload.svg'
import { ReactComponent as Plus } from './plus.svg'
import { ReactComponent as Error } from './error.svg'
import { ReactComponent as Warning } from './warning.svg'
import { ReactComponent as Check } from './check.svg'
import { ReactComponent as Success } from './success.svg'
import { ReactComponent as Eye } from './eye.svg'
import { ReactComponent as EyeCrossed } from './eye-crossed.svg'
import { ReactComponent as Phone } from './phone.svg'
import { ReactComponent as Card } from './card.svg'
import { ReactComponent as Frown } from './frown.svg'
import { ReactComponent as DropArrow } from './drop-arrow.svg'
import { ReactComponent as Sort } from './sort.svg'
import { ReactComponent as Filter } from './filter.svg'
import { ReactComponent as Search } from './search.svg'
import { ReactComponent as Clock } from './clock.svg'
import { ReactComponent as Location } from './location.svg'
import { ReactComponent as Menu } from './menu.svg'
import { ReactComponent as Instagram } from './insta.svg'
import { ReactComponent as Facebook } from './facebook.svg'
import { ReactComponent as Logout } from './logout.svg'
import { ReactComponent as User } from './user.svg'
import { ReactComponent as Home } from './home.svg'
import { ReactComponent as People } from './people.svg'
import { ReactComponent as File } from './file.svg'
import { ReactComponent as Printer } from './printer.svg'
import { ReactComponent as EllipsisVertical } from './ellipsis-vertical.svg'
import { ReactComponent as Copy } from './copy.svg'
import { ReactComponent as ReloadClock } from './reload-clock.svg'
import { ReactComponent as PersonAdd } from './person-add.svg'
import { ReactComponent as Prize } from './prize.svg'
import { ReactComponent as Go } from './go.svg'
import { ReactComponent as Dollar } from './dollar.svg'
import { ReactComponent as Lock } from './lock.svg'
import { ReactComponent as Person } from './person.svg'

const propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.any
}

const Icon = ({ icon, size, ...props }) => {
  if (size) {
    props.width = size
    props.height = size
  }
  switch (icon) {
    case 'close':
      return <Close {...props}/>
    case 'pencil':
      return <Pencil {...props}/>
    case 'back':
      return <Arrow style={{ transform: 'rotate(180deg)' }} {...props}/>
    case 'forward':
      return <Arrow {...props}/>
    case 'down':
      return <Arrow style={{ transform: 'rotate(90deg)' }} {...props} />
    case 'loader':
      return <Loader {...props}/>
    case 'reload':
      return <Reload {...props}/>
    case 'mail':
      return <Mail {...props}/>
    case 'plus':
      return <Plus {...props}/>
    case 'error':
      return <Error {...props}/>
    case 'warning':
      return <Warning {...props}/>
    case 'check':
      return <Check {...props}/>
    case 'success':
      return <Success {...props}/>
    case 'eye':
      return <Eye {...props}/>
    case 'eye-crossed':
      return <EyeCrossed {...props}/>
    case 'phone':
      return <Phone {...props}/>
    case 'card':
      return <Card {...props}/>
    case 'frown':
      return <Frown {...props}/>
    case 'drop-arrow':
      return <DropArrow {...props}/>
    case 'sort':
      return <Sort {...props}/>
    case 'filter':
      return <Filter {...props}/>
    case 'search':
      return <Search {...props}/>
    case 'clock':
      return <Clock {...props}/>
    case 'location':
      return <Location {...props}/>
    case 'menu':
      return <Menu {...props}/>
    case 'instagram':
      return <Instagram {...props}/>
    case 'facebook':
      return <Facebook {...props}/>
    case 'logout':
      return <Logout {...props}/>
    case 'user':
      return <User {...props}/>
    case 'home':
      return <Home {...props}/>
    case 'people':
      return <People {...props}/>
    case 'file':
      return <File {...props}/>
    case 'printer':
      return <Printer {...props}/>
    case 'ellipsis-vertical':
      return <EllipsisVertical {...props}/>
    case 'copy':
      return <Copy {...props}/>
    case 'person-add':
      return <PersonAdd {...props}/>
    case 'reload-clock':
      return <ReloadClock {...props}/>
    case 'prize':
      return <Prize {...props} />
    case 'go-right':
      return <Go style={{ transform: 'rotate(270deg)' }} {...props} />
    case 'dollar':
      return <Dollar {...props} />
    case 'lock':
      return <Lock {...props} />
    case 'person':
      return <Person {...props} />
  }
}

Icon.propTypes = propTypes

export default Icon
