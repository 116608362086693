export const ROUTES = {
  Home: '/home',
  ForgotPassword: '/forgot-password',
  ChangePassword: '/change-password',
  SignUp: '/signup',
  StaffLogIn: '/login/staff',
  CompleteProfile: '/complete-profile',
  Dashboard: '/dashboard',
  TokenRefresh: '/token-refresh',
  Order: '/order',
  Orders: '/orders',
  Auth: '/__auth',
  Tournaments: '/events',
  SelectTournament: '/events/select',
  CreateTournament: '/events/create',
  Reports: '/reports',
  PrinterSettings: '/printer-settings',
  Unauthorized: '/unauthorized',
  AboutUs: '/about-us',
  Invoices: '/invoices',
  Customers: '/customers',
  MyProfile: '/my-profile'
}
