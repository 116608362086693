import { find } from 'lodash'
import { useEffect, useState } from 'react'
import useDataService from '../../hooks/useDataService'
import { Dropdown } from 'react-bootstrap'
import PropTypes from 'prop-types'

const propTypes = {
  value: PropTypes.object.isRequired,
  handleSelect: PropTypes.func.isRequired
}

const TournamentDropdown = ({ value, handleSelect }) => {
  const [tournaments, setTournaments] = useState([])
  const { apiGetTournaments } = useDataService()

  useEffect(() => {
    apiGetTournaments({ approved: true }, 1, 100).then(res => setTournaments(res.tournaments))
  }, [])

  useEffect(() => {
    if (tournaments.length > 0 && value && value._id && !value.name) {
      handleSelect(find(tournaments, t => t._id === value._id))
    }
  }, [tournaments])

  const handleTournamentSelection = (eventKey) => {
    const t = find(tournaments, t => t._id === eventKey)
    handleSelect(t)
  }

  return (
    <Dropdown
      onSelect={handleTournamentSelection}
      className='w-100'
    >
      <Dropdown.Toggle variant='input' className='w-100 justify-content-between'>
        {value ? value.name ?? find(tournaments, t => t._id === value._id)?.name : 'Select tournament' }
      </Dropdown.Toggle>

      <Dropdown.Menu className='w-100 bg-white shadow-sm'>
        {tournaments.map(t => (
          <Dropdown.Item key={t._id} eventKey={t._id}>{t.name}</Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

TournamentDropdown.propTypes = propTypes

export default TournamentDropdown
