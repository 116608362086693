import { useFormikContext } from 'formik'
import DateRangeFields from './DateRangeFields'
import { Button, Form } from 'react-bootstrap'

const PlayerInvoiceForm = () => {
  const { handleSubmit, isSubmitting } = useFormikContext()

  return (
    <Form onSubmit={handleSubmit} noValidate className='mx-0 px-2 gy-1 mb-3'>
      <DateRangeFields />
      <div className='text-center'>
        <Button
          type='submit'
          variant='primary'
          disabled={isSubmitting}
        >Download invoice</Button>
      </div>
    </Form>
  )
}

export default PlayerInvoiceForm
