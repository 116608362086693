import { capitalize, round, cloneDeep, find } from 'lodash'
import { Formik, useFormikContext } from 'formik'
import { Button, Col, Container, Dropdown, DropdownButton, Form, InputGroup, Modal, Row } from 'react-bootstrap'
import Feedback from 'react-bootstrap/esm/Feedback'
import Icon from '../../icons/Icon'
import StringSelection from './StringSelection'
import StepperInput from '../generic/StepperInput'
import { Racquet } from '../../classes/Racquet'
import { bool, mixed, number, object, string } from 'yup'
import PropTypes from 'prop-types'
// import FormikDebug from '../generic/FormikDebug'
import { useContext, useState } from 'react'
import { OrderFormContext } from '../../contexts/OrderFormContext'
import ConfirmationModal from '../generic/ConfirmationModal'
import { SharedDataContext } from '../../contexts/SharedDataContext'

const propTypes = {
  show: PropTypes.bool.isRequired,
  index: PropTypes.number,
  handleAdd: PropTypes.func.isRequired,
  handleReplace: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
}

const RacquetModal = ({ show, index, handleAdd, handleReplace, handleClose }) => {
  const { tournament } = useContext(SharedDataContext)
  const { racquets } = useContext(OrderFormContext)
  const { values: parentValues } = useFormikContext()
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const stringsSelectionSchema = object({
    ownStrings: bool().required(),
    title: string().required(),
    other: string().when('title', {
      is: 'other',
      then: (schema) => schema.required('Enter strings name'),
      otherwise: (schema) => schema.optional()
    })
  })

  const stringAxisSchemaRequired = object({
    strings: stringsSelectionSchema,
    tension: number('This must be a number').transform(value => isNaN(value) ? 0 : value).required().positive('Enter a number'),
    preStretch: string().nullable().matches(/^([0-9]{0,2}|100)$/, 'Enter a number between 0 and 100')
  })

  const stringAxisSchemaOptional = object({
    strings: mixed(),
    ownStrings: bool(),
    tension: number('This must be a number').transform(value => isNaN(value) ? 0 : value),
    preStretch: string().nullable().matches(/^([0-9]{0,2}|100)$/, 'This must be a positive number')
  })

  const racquetSchema = object({
    name: string().required('Enter racquet name'),
    sameMainsCrosses: bool().required(),
    mains: stringAxisSchemaRequired,
    crosses: object().when('sameMainsCrosses', {
      is: true,
      then: (schema) => schema.concat(stringAxisSchemaOptional),
      otherwise: (schema) => schema.concat(stringAxisSchemaRequired)
    }),
    knots: number().required().oneOf([2, 4]),
    stencil: object({
      logo: string().required().oneOf(['none', 'racquet', 'strings']),
      color: string().when('logo', {
        is: 'none',
        then: (schema) => schema.defined(),
        otherwise: (schema) => schema.oneOf(['red', 'black', 'white'], 'Select stencil color')
      })
    }),
    overgrip: bool().required(),
    notes: string().nullable().max(140)
  })

  const prepareRacquet = (racquet) => {
    const r = cloneDeep(racquet)
    r.price = 0
    if (racquet.sameMainsCrosses) {
      r.crosses = cloneDeep(r.mains)
    }
    ['mains', 'crosses'].forEach(axis => {
      r[axis].tension = Number(r[axis].tension)
      if (r[axis].strings.ownStrings) {
        r[axis].strings = {
          own: {
            ...r[axis].strings,
            title: r[axis].strings.title === 'other' ? r[axis].strings.other : r[axis].strings.title,
            halfsetPrice: 0,
            fullsetPrice: 0
          },
          ownStrings: true,
          otherSpecified: r[axis].strings.title === 'other'
        }
        r.price += 0
      } else {
        r[axis].strings = {
          purchased: cloneDeep(r[axis].strings),
          ownStrings: false,
          otherSpecified: false
        }
        r.price += r[axis].strings.purchased.halfsetPrice
      }
    })

    r.addOns = [find(tournament.addOns, a => a._id === 'RESTR_LABOUR')]
    if (r.overgrip) {
      const overgripAddOn = find(tournament.addOns, a => a._id === 'OVERGRIP')
      r.addOns.push(overgripAddOn)
    }
    r.price = round(r.addOns.reduce((prev, curr) => prev + curr.price, r.price), 2)

    return r
  }

  const parseRacquet = (racquet) => {
    const r = cloneDeep(racquet)

    Array.from(['mains', 'crosses']).forEach(axis => {
      if (r[axis].strings.ownStrings) {
        r[axis].strings = {
          ownStrings: true,
          title: r[axis].strings.otherSpecified ? 'other' : r[axis].strings.own.title ?? r[axis].strings.own,
          other: r[axis].strings.otherSpecified ? r[axis].strings.own.title ?? r[axis].strings.own : undefined
        }
      } else {
        r[axis].strings = {
          ownStrings: false,
          ...r[axis].strings.purchased
        }
      }
    })

    return r
  }

  const handleSubmit = (values, bag) => {
    if (index === undefined) {
      handleAdd(prepareRacquet(values))
    } else {
      handleReplace(index, prepareRacquet(values))
    }
    handleClose()
  }
  // animation={false} dialogClassName='slide-in-bottom'
  return (
    <Modal show={show} fullscreen={true} >
      <ConfirmationModal
        show={showConfirmationModal}
        headerText='Are you sure?'
        bodyText='This racquet will not be saved. Press the Save button at the bottom to save the racquet.'
        actionButtonText='Close'
        handleCancel={() => setShowConfirmationModal(false)}
        handleConfirm={() => {
          setShowConfirmationModal(false)
          handleClose()
        }}
      />
      <Modal.Body>
        <Container className='pt-5 mw-750'>
          <Formik
            initialValues={index === undefined ? new Racquet(parentValues.tournament.retailVenue) : parseRacquet(parentValues.racquets[index])}
            validationSchema={racquetSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleSubmit
            }) => (
              <Form noValidate onSubmit={evt => {
                console.log(errors)
                handleSubmit(evt)
              }}>
                <Row className='justify-content-between mb-3'>
                  <Col className='d-flex align-items-top'>
                    <h3 className='pe-5'>
                      {index === undefined
                        ? 'Add racquet'
                        : 'Edit racquet'
                      }
                    </h3>
                  </Col>
                  <Col xs='auto'>
                    <Button type='button' variant='icon-wrapper' onClick={() => setShowConfirmationModal(true)}><Icon icon='close' /></Button>
                  </Col>
                </Row>

                <h5 className='mb-3'>Racquet</h5>
                <Form.Group controlId='name' className='mb-5'>
                    <Form.Control
                      type="text"
                      value={values.name}
                      onChange={handleChange}
                      placeholder='Racquet name'
                      isInvalid={!!errors.name && touched.name}
                      list='racquet-names'
                    />
                    <datalist id='racquet-names'>
                      {racquets.map(r => (
                        <option key={r._id} value={r.name}/>
                      ))}
                    </datalist>
                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                </Form.Group>

                <h5 className="mb-5">Stringing preferences</h5>

                <Form.Group controlId='sameMainsCrosses' className='mb-5'>
                  <Form.Check type='switch' >
                    <Form.Check.Label>Set the same parameters for all strings</Form.Check.Label>
                    <Form.Check.Input
                      onChange={(event) => {
                        setFieldValue('sameMainsCrosses', event.target.checked)
                        if (!event.target.checked) {
                          setFieldValue('crosses', values.mains)
                        }
                      }}
                      checked={values.sameMainsCrosses}
                    />
                  </Form.Check>
                </Form.Group>
                {(values.sameMainsCrosses ? ['mains'] : ['mains', 'crosses']).map(axis => (
                  <Row key={axis}>
                    <h5 className='mb-5'>{values.sameMainsCrosses ? 'Mains and crosses' : capitalize(axis)}</h5>
                    <Col xs={12}>
                      <StringSelection axis={axis} />
                    </Col>
                    <Form.Group as={Col} xs={12} md={6} controlId={`${axis}.preStretch`} className='mb-5'>
                      <InputGroup hasValidation>
                        <Form.Control
                          type="text"
                          inputMode='numeric'
                          value={values[axis].preStretch}
                          onChange={handleChange}
                          placeholder='String pre-stretch'
                          isInvalid={!!errors[axis]?.preStretch && touched[axis]?.preStretch}
                        />
                        <InputGroup.Text>%</InputGroup.Text>
                        <Form.Control.Feedback type="invalid">{errors[axis]?.preStretch}</Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col} xs={12} md={6} controlId={`${axis}.tension`} className='mb-5'>
                      <InputGroup hasValidation>
                        <Form.Control
                          type="text"
                          inputMode='numeric'
                          value={values[axis].tension}
                          onChange={handleChange}
                          placeholder="String tension"
                          isInvalid={!!errors[axis]?.tension && touched[axis]?.tension}
                        />
                        <DropdownButton title={values.measure} variant='light'>
                          <Dropdown.Item onClick={() => setFieldValue('measure', 'lb')}>lb</Dropdown.Item>
                          <Dropdown.Item onClick={() => setFieldValue('measure', 'kg')}>kg</Dropdown.Item>
                        </DropdownButton>
                        <Form.Control.Feedback type="invalid">{errors[axis]?.tension}</Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Row>
                ))}

                <Form.Group className='d-flex justify-content-between mb-5'>
                  <Form.Label>Knots</Form.Label>
                  <InputGroup className='w-auto'>
                    {[2, 4].map(count => (
                      <Button
                        key={count}
                        type='button'
                        variant='light'
                        onClick={() => setFieldValue('knots', count)}
                        active={values.knots === count}
                      >
                        {count}
                      </Button>
                    ))}
                  </InputGroup>
                </Form.Group>

                <div className='mb-5'>
                  <Dropdown onSelect={eventKey => setFieldValue('stencil.logo', eventKey)} className='mb-2'>
                    <Dropdown.Toggle
                      variant='input'
                      className='w-100 justify-content-between'
                    >
                      {values.stencil.logo === 'none' && 'No stencil'}
                      {values.stencil.logo === 'racquet' && 'Racquet brand stencil'}
                      {values.stencil.logo === 'strings' && 'Strings brand stencil'}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='w-100 bg-white shadow-sm'>
                      <Dropdown.Item eventKey='none' active={values.stencil.logo === 'none'}>No stencil</Dropdown.Item>
                      <Dropdown.Item eventKey='racquet' active={values.stencil.logo === 'racquet'}>Racquet brand stencil</Dropdown.Item>
                      <Dropdown.Item eventKey='strings' active={values.stencil.logo === 'strings'}>Strings brand stencil</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  {values.stencil.logo !== 'none' &&
                    <Dropdown onSelect={eventKey => setFieldValue('stencil.color', eventKey)} className='mb-2'>
                      <Dropdown.Toggle
                        variant='input'
                        className={`w-100 justify-content-between ${errors.stencil?.color && touched.stencil?.color ? 'is-invalid' : ''}`}
                      >
                        {values.stencil.color === '-' ? 'Select color' : capitalize(values.stencil.color)}
                      </Dropdown.Toggle>
                      <Feedback type='invalid'>{errors.stencil?.color}</Feedback>
                      <Dropdown.Menu className='w-100 bg-white shadow-sm'>
                        <Dropdown.Item eventKey='red' active={values.stencil.color === 'red'}>Red</Dropdown.Item>
                        <Dropdown.Item eventKey='black' active={values.stencil.color === 'black'}>Black</Dropdown.Item>
                        <Dropdown.Item eventKey='white' active={values.stencil.color === 'white'}>White</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  }
                </div>

                <Form.Group controlId='overgrip' className='mb-5'>
                  <Form.Check type='switch' >
                    <Form.Check.Label>Replace overgrip</Form.Check.Label>
                    <Form.Check.Input
                      onChange={e => setFieldValue('overgrip', e.target.checked)}
                      checked={values.overgrip}
                    />
                  </Form.Check>
                </Form.Group>

                <Form.Group controlId='notes' className='mb-5'>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={values.notes}
                    onChange={handleChange}
                    placeholder="Additional notes"
                    maxLength={140}
                    isInvalid={!!errors.notes && touched.notes}
                  />
                  <p className='text-small text-muted text-end mb-0 mt-1'>{values.notes.length}/140</p>
                  <Form.Control.Feedback type="invalid">{errors.notes}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className='d-flex justify-content-between mb-5'>
                  <Form.Label>Quantity</Form.Label>
                  <StepperInput
                    value={values.quantity}
                    increment={() => setFieldValue('quantity', values.quantity + 1)}
                    decrement={() => setFieldValue('quantity', values.quantity - 1)}
                    min={1}
                  />
                </Form.Group>
                <Row className='justify-content-center mb-4'>
                  <Col xs={12} md='auto'>
                    <Button type='submit' className='w-100'>
                      {index === undefined
                        ? 'Add racquet'
                        : 'Save racquet'
                      }
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      </Modal.Body>
    </Modal>
  )
}

RacquetModal.propTypes = propTypes

export default RacquetModal
