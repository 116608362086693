import _ from 'lodash'
import { useContext, useEffect, useState } from 'react'
import useDataService from '../../hooks/useDataService'
import { Button, Col, Container, Dropdown, Form, Row } from 'react-bootstrap'
import { Formik } from 'formik'
import { EventContext } from '../../contexts/EventContext'
import { object, string } from 'yup'
import { SharedDataContext } from '../../contexts/SharedDataContext'

const PrinterSettingsForm = () => {
  const emptyPrinterSettings = {
    computer: {
      id: '',
      name: ''
    },
    racquetTagPrinter: {
      id: '',
      name: ''
    },
    throatStickerPrinter: {
      id: '',
      name: ''
    }
  }

  const parsePrinterSettings = data => {
    if (data.printerSettings) {
      return {
        ...data.printerSettings,
        computer: {
          id: data.printerSettings?.racquetTagPrinter?.computer?.id ?? data.printerSettings?.throatStickerPrinter?.computer?.id,
          name: data.printerSettings?.racquetTagPrinter?.computer?.name ?? data.printerSettings?.throatStickerPrinter?.computer?.name
        }
      }
    } else {
      return emptyPrinterSettings
    }
  }

  const { setBanner } = useContext(SharedDataContext)
  const { event } = useContext(EventContext)
  const [printerSettings, setPrinterSettings] = useState(parsePrinterSettings(event))
  const { apiGetPrinters, apiPutPrinterSettings } = useDataService()
  const [printers, setPrinters] = useState([])
  const [computers, setComputers] = useState([])

  const validationSchema = object({
    computer: object({
      id: string().required()
    }),
    racquetTagPrinter: object({
      id: string().required()
    }),
    throatStickerPrinter: object({
      id: string().optional()
    })
  })

  useEffect(() => {
    apiGetPrinters().then(res => {
      setComputers(_.uniqBy(res.map(p => p.computer), c => c.id))
      setPrinters(res)
    })
  }, [])

  const handleSave = async (data, bag) => {
    bag.setSubmitting(true)
    const res = await apiPutPrinterSettings(event._id, data)
    setPrinterSettings(parsePrinterSettings(res))
    setBanner('Saved successfully')
    bag.setSubmitting(false)
  }

  return (
    <Container className='ps-md-5 pt-2 ms-md-0 mw-750'>
      <Formik
        initialValues={printerSettings}
        initialErrors={printerSettings.computer.id === '' ? { computer: 1 } : {}}
        validationSchema={validationSchema}
        onSubmit={handleSave}
      >
        {({
          values,
          errors,
          handleSubmit,
          setFieldValue,
          validateForm,
          isValid,
          isSubmitting
        }) => (

          <Form noValidate onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} md={8}>
                <h4 className='mb-2'>Choose a computer</h4>
                <p>Make sure to pick a computer that works for your location.<br />If unsure, ask your colleagues for help.</p>
                <Dropdown
                  onSelect={async (eventKey) => {
                    const fieldName = 'computer'
                    const computer = _.find(computers, c => c.id.toString() === eventKey)
                    await setFieldValue(`${fieldName}.id`, computer.id)
                    await setFieldValue(`${fieldName}.name`, computer.name)
                    validateForm()
                    const resetFields = ['racquetTagPrinter', 'throatStickerPrinter']
                    resetFields.forEach(f => {
                      setFieldValue(`${f}.id`, '')
                      setFieldValue(`${f}.name`, '')
                      setFieldValue(`${f}.computer.id`, '')
                      setFieldValue(`${f}.computer.name`, '')
                    })
                  }} className='mt-2 mb-4'>
                  <Dropdown.Toggle variant="input" className='w-100 justify-content-between'>
                    {values.computer?.id !== '' ? values.computer?.name : 'Select computer'}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className='w-100'>
                    {computers.map(c => (
                      <Dropdown.Item key={c.id} eventKey={c.id}>{c.name} <span className={`${c.state === 'disconnected' ? 'text-danger' : 'text-success'}`}>&#x2022; {c.state}</span></Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>

                <div className={`${errors.computer ? 'opacity-25' : ''}`}>
                  <h4 className='mb-2'>Raquet tag printer</h4>
                  <p>Prints stickers with detailed order information to help stringers understand the order requirements.</p>
                  <Dropdown
                    onSelect={async (eventKey) => {
                      const fieldName = 'racquetTagPrinter'
                      const printer = _.find(printers, p => p.id.toString() === eventKey)
                      await setFieldValue(`${fieldName}.id`, printer.id)
                      await setFieldValue(`${fieldName}.name`, printer.name)
                      await setFieldValue(`${fieldName}.computer.id`, printer.computer.id)
                      await setFieldValue(`${fieldName}.computer.name`, printer.computer.name)
                      validateForm()
                    }}
                    className='mt-2 mb-4'
                  >
                    <Dropdown.Toggle variant="input" disabled={errors.computer} className='w-100 justify-content-between'>
                      {values.racquetTagPrinter.id !== '' ? values.racquetTagPrinter.name : 'Select printer'}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className='w-100'>
                      {printers.filter(p => p.computer.id === parseInt(values.computer.id)).map(p => (
                        <Dropdown.Item key={p.id} eventKey={p.id}>{p.name} <span className={`${p.state === 'online' ? 'text-success' : 'text-danger'}`}>&#x2022; {p.state}</span></Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>

                  <h4 className='mb-2 mt-2'>Throat sticker printer</h4>
                  <p>Prints stickers with a summary of stringing parameters to help players quickly identify a racquet.</p>
                  <Dropdown
                    onSelect={async (eventKey) => {
                      const fieldName = 'throatStickerPrinter'
                      const printer = _.find(printers, p => p.id.toString() === eventKey)
                      await setFieldValue(`${fieldName}.id`, printer.id)
                      await setFieldValue(`${fieldName}.name`, printer.name)
                      await setFieldValue(`${fieldName}.computer.id`, printer.computer.id)
                      await setFieldValue(`${fieldName}.computer.name`, printer.computer.name)
                      validateForm()
                    }}
                    className='mt-2 mb-4'
                  >
                    <Dropdown.Toggle variant="input" disabled={errors.computer} className='w-100 justify-content-between'>
                      {values.throatStickerPrinter.id !== '' ? values.throatStickerPrinter.name : 'Select printer'}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className='w-100'>
                      {printers.filter(p => p.computer.id === parseInt(values.computer.id)).map(p => (
                        <Dropdown.Item key={p.id} eventKey={p.id}>{p.name} <span className={`${p.state === 'online' ? 'text-success' : 'text-danger'}`}>&#x2022; {p.state}</span></Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
            </Row>
            {isValid && <Button
              disabled={isSubmitting}
              type='submit'
              variant='primary'
              className='mb-4'
            >Save settings</Button>}
          </Form>
        )
        }
      </Formik>
    </Container>
  )
}

export default PrinterSettingsForm
